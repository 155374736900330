.btn-holder-reinstall {
    height: 48px;
    width: 340px;
    border-radius: 10px;
    background: #00D108;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.btn-txt-place-holder {
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    text-align: center;
    color: white;
}

