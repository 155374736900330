.thank-you-page-container {
    width: 100%;
    background: #F6FBFF;
}

.body-container-thanks-page {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: #F6FBFF;
}

.description-container-thanks-page {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}

.description-thank-you-page-container {
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    text-align: center;
    color: black;

}

.input-thank-you-container {
    margin-top: 20px;
}

.input-thank-you {
    height: 36px;
    width: 340px;
    left: 470px;
    top: 268px;
    border-radius: 8px;
    background: white;
    border: 1px solid #6D6D6D;
    box-sizing: border-box;
    padding-left: 10px;
}

.content-container-thank-you-page {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.img-thank-you-container {
    margin-top: 40px;
}

.header-container-title-thank-you-page {
    font-family: 'Inter', sans-serif;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 36px;
    text-align: center;
    color: black;
}

.btn-holder-submit-feed {
    margin-top: 20px;
}

.footer-container-thank-you-page {
    margin-top: 50px;
}

.email-not-success {
    font-family: 'Inter', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 15px;
    margin-top: 4px;
    color: #FF0000;

}
