.popup_overlay{
    position: fixed;
    width: 100%;
    height: 100%;
    top:0;
    left: 0;
    background: rgba(0, 0, 0, 0.4);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2147483648
}
.update_extension_popup{
    width: 870px;
    min-width: 870px;
    height: 376px;
    min-height: 594px;
    display: flex;
    align-items: center;
    flex-direction: column;
    border-radius: 40px;
    border: 2px solid #FFFFFF;
    background: white url("../../assets/update_popup_bg.png") no-repeat center;
    background-size: cover;
}
.update_extension_popup .popup_logo{
    width: 240px;
    height: 66px;
    margin-top: 35px;
}
.update_extension_popup .popup_logo img{
    width: 100%;
}
.update_extension_popup .popup_title{
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 34px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #FFFFFF;
    text-shadow: 0 0 24px rgba(0, 0, 0, 0.25);
    margin-top: -22px;
}
.update_extension_popup .popup_preview_wrapper{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 788px;
    height: 330px;
    margin: 18px auto 0;
}
.update_extension_popup .popup_preview{
    width: 380px;
    height: 330px;
    background: rgba(0, 0, 0, 0.3);
    border: 1px solid #000000;
    border-radius: 34px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 24px;
}
.update_extension_popup .preview_image{
    height: 188px;
    box-shadow: 0 0 5px #5599FF;
    overflow: hidden;
    margin-top: 31px;
}
.update_extension_popup .portal_preview .preview_image{
    width: 272px;
    border-radius: 10px;
}
.update_extension_popup .extension_preview .preview_image{
    width: 137px;
    border-radius: 7px;
}

.update_extension_popup .preview_image img{
    width: 100%;
}
.update_extension_popup .preview_title{
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 900;
    font-size: 20px;
    line-height: 24px;
    color: #FFFFFF;
    margin-top: 10px;
    margin-bottom: 0;
}
.update_extension_popup .preview_subtitle{
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 21px;
    color: #FFFFFF;
    margin-top: 2px;
    text-align: center;
}
.update_extension_popup .popup_btn{
    margin-top: 30px;
    box-sizing: border-box;
    background: linear-gradient(269.42deg, #006EDD 0%, #2B93F3 100%);
    border: 1px solid rgba(255, 255, 255, 0.4);
    border-radius: 30px;
    width: 374px;
    height: 54px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #FFFFFF;
}
.update_extension_popup .popup_btn:hover{
    background: linear-gradient(269.42deg, #004B97 0%, #005BAF 100%);
}
