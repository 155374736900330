.body-collection-page-container {
    background:  #F6FBFF;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.collection-cards-main-container{
    width: 100%;
    background: #F6FBFF;
}

.footer-sizing{
    margin-top: 50px;
}

.header-txt-cursor-collection{
    font-family: 'Montserrat', sans-serif;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 44px;
    text-align: center;
    background-image: linear-gradient(269.42deg, #006EDD 0%, #004585 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-top: 40px;
}

.cursor-collection-page-last-used {
    height: 380px;
    width: 100%;
    background-color: #FFFFFF;
}

.your-last-used-packs-txt {
    font-family: 'Inter', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    color: black;
    padding-top: 40px;
}

.last-used-card-container-cursor-collection-page > :nth-child(4n+1) { margin-left: 0; }
.last-used-card-container-cursor-collection-page > :first-child { margin-left: 0; }

.last-used-card-container-cursor-collection-page {
    margin-top: 10px;
    width: 100%;
    display: flex;
    box-sizing: border-box;
}

.collections-card-container{
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit , minmax(260px, 1fr));
    grid-gap: 8px;
    justify-content: space-between;
    margin-top: 20px;
}

.spinner {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: 1177px) {
 .collections-card-container {
     grid-gap: 101px;
 }

}

@media screen and (max-width: 1081px) {
    .collections-card-container {
        grid-gap: 71px;
    }

}



@media screen and (max-width: 1010px) {
    .collections-card-container {
        grid-gap: 13px;
    }
}

.see-all-style {
    font-family: 'Inter', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    color: #197DE1;
    cursor: pointer;
    text-decoration: none;
}

.see-all-style:hover {
    text-decoration: underline;
}

.see-all-txt-last-used {
    margin-top: 15px;
    display: flex;
    justify-content: flex-end;
}

.mt-to-collection-card{
    margin-top: 20px;
    margin-left: 9.3px;
}

.btn-margin{
    display: flex;
    justify-content: center;
    margin-top: 25px;
}

.txt-collection-page-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
}

.description-txt-cursor-collection-page{
    height: 38px;
    left: 205px;
    top: 144px;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 10px;
}

.description-txt-style-collection-page{
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px;
    text-align: center;

}
