.view-collection-btn-container{
    height: 40px;
    width: 246px;
    border-radius: 6px;
    background: #006EDD;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    text-decoration: none;
}

.view-collection-btn-container:hover{
    background: #004D9B;
}

.view-collection-txt{
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 19px;
    text-align: center;
    color: white;
}
