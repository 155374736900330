.collection-card-container{
    height: 247px;
    width: 278px;
    border-radius: 10px;
    background: #FFFFFF;
    border: 1px solid #D2D2D2;
    box-shadow: 0 0 8px 0 #00000026;
    display: flex;
    align-items: center;
    flex-direction: column;
    text-decoration: none;
    cursor: pointer;
}

.img-collection-card-container{
    height: 124px;
    width: 276px;
    left: 1px;
    top: 1px;
    border-radius: 9px 9px 0 0;
}

.image-size-collection-card {
    width: 276px;
    height: 124px;
}

.collection-name-txt.container{
    height: 17px;
    width: 184px;
    left: 47px;
    top: 143px;

}

.collection-name-txt{
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 17px;
    text-align: center;
    color: black;
    margin-top: 25px;
}

.view-btn-collection-card{
    margin-top: 15px;
    background: #F6FBFF;
    height: 67px;
    width: 276px;
    left: 1px;
    top: 179px;
    border-radius: 0px 0px 9px 9px;
    display: flex;
    justify-content: center;
    align-items: center;

}
