.see-all-container{
    height: 44px;
    width: 265px;
    border-radius: 10px;
    border: 2px solid #197DE1;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: default;
    box-sizing: border-box;
    text-decoration: none;
}

.see-all-container:hover{
    border: 4px solid #197DE1;
    box-sizing: border-box;
}

.see-all-txt{
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    text-align: center;
    color:  #197DE1;
}
