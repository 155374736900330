.txt-header-privacy-container {
    display: flex;
}

.container-policy {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;
    background: #FFFFFF;
    box-sizing: border-box;
}

.txt-privacy-policy {
    font-family: 'Montserrat',sans-serif;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 44px;
    margin-top: 40px;
    background-image: linear-gradient(269.42deg, #006EDD 0%, #004585 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.main-text-privacy {
    margin-top: 10px;
}

.header-privacy-txt {
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 17px;
    color: black;
    margin-top: 20px;
    text-align: left;
}

.first-text {
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 17px;
    color: black;
    text-align: left;
}

.footer-policy {
    margin-top: 50px;
}
