.btn-submit-feed-back {
    height: 48px;
    width: 340px;
    border-radius: 10px;
    background: #ADADAD;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: default;
}

.active-btn-submit-feed-back {
    height: 48px;
    width: 340px;
    border-radius: 10px;
    background: #00D108;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.active-btn-submit-feed-back:hover {
    background: #0BB812;
}

.txt-container-submit-feed-back {
    font-family: 'Inter',sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    text-align: center;
    color: white;

}
