.inner_layout{
    max-width: 1140px;
    margin: 0 auto;
    box-sizing: border-box;
}

@media screen and (max-width: 1176px) {
    .inner_layout {
        max-width: 1040px;
    }
}

@media screen and (max-width: 1080px) {
    .inner_layout {
        max-width: 980px;
    }
}

@media screen and (max-width: 1010px) {
    .inner_layout {
        max-width: 850px;
    }
}


