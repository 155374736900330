.card-container{
    height: 230px;
    width: 204px;
    border-radius: 10px;
    background: #FFFFFF;
    box-shadow: 0 0 8px 0 #00000026;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
}

.card-container:hover{
    border:  2px solid #ADADAD;
    box-sizing: border-box;
}

.card-container:hover .cursor-name-txt-container {
    margin-top: 8px;
}


.card-container:hover .cursor-name-txt{
     background-image: linear-gradient(269.42deg, #006EDD 0%, #004585 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    box-sizing: border-box;
}

.cursor-name-txt-container{
    height: 30px;
    width: 184px;
    left: 10px;
    top: 14px;
    margin-top: 10px;
    cursor: default;
    box-sizing: border-box;
}

.cursor-name-txt{
    font-family: 'Inter', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 15px;
    text-align: center;
    color: #000000;
    box-sizing: border-box;
}

.img-card-container{
    height: 76px;
    width: 152px;
    left: 26px;
    top: 58px;
    margin-top: 15px;
    box-sizing: border-box;
}

.add-button-card{
    margin-top: 7px;
    box-sizing: border-box;
}

.image-collection-cursor-card {
    width: 140px;
    height: 75px;
    box-sizing: border-box;
}

.button-container{
    margin-top: 25px;
    box-sizing: border-box;
}
