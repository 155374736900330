.popup_overlay{
    position: fixed;
    width: 100%;
    height: 100%;
    top:0;
    left: 0;
    background: rgba(0, 0, 0, 0.4);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2147483648
}
.popup_bordered{
    width: 788px;
    min-width: 788px;
    height: 376px;
    min-height: 376px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
    background: linear-gradient(45deg, rgba(146, 254, 157, 0.9) 0%, rgba(0, 142, 181, 0.9) 100%);
}
.popup{
    width: 780px;
    min-width: 780px;
    height: 368px;
    min-height: 368px;
    background: #181818;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 30px;
    margin: 0 !important;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 0 28px;
    position: relative;
}
.popup_logo{
    margin-top: 40px;
}
.popup_title{
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 44px;
    text-align: center;
    color: #FFFFFF;
    margin-top: 53px;
}
.popup.congrats .popup_title{
    font-size: 34px;
    line-height: 41px;
    margin-top: 12px;
}
.popup_title span{
    text-decoration-line: underline;
}
.popup_btn{
    width: 420px;
    height: 60px;
    background: linear-gradient(45deg, #92FE9D 0%, #00C9FF 100%);
    box-shadow: 0 8px 24px -4px #4CE5CD;
    border-radius: 30px;
    margin-top: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    line-height: 31px;
    color: #FFFFFF;
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.35);
    cursor: pointer;
}
.popup_btn:hover{
    background: linear-gradient(45deg, rgba(146, 254, 157, 0.9) 0%, rgba(0, 142, 181, 0.9) 100%);
}
.popup_btn img{
    margin-right: 12px;
    margin-top: 4px;
}
.popup_arrow{
    position: absolute;
    top: 100px;
    left: 90px;
}
.close_btn{
    position: absolute;;
    top:20px;
    right: 20px;
    width: 24px;
    height: 24px;
    cursor: pointer;
}
.popup .close_btn img{
    width: 24px;
}
