.footer-container {
    width: 100%;
    background: linear-gradient(269.42deg, #006EDD 0%, #004585 100%);
}

.privacy {
    margin-top: -19px;
}

.menu-style-footer{
    display: flex;
    height: 100px;
    justify-content: space-around;
    align-items: center;
}



.vector-container{
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: default;
    text-decoration: none;
}

.logo-txt-container{
    height: 34px;
    width: 69px;
    left: 32px;
    margin-left: 15px;
}

.logo-txt{
    font-family: 'Montserrat Alternates', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 17px;
    text-align: left;
    color: white;
}

.col{
    display: flex;
    flex-direction: column;
}

.txt-style{
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
    text-align: left;
    color: white;
    cursor: pointer;
    text-decoration: none;
}

.mt{
    margin-top: 8px;
}
