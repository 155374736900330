.action-bar{
    width: 100%;
    background: linear-gradient(269.42deg, #006EDD 0%, #004585 100%);
}

.logo-img-vector {
    cursor: pointer;
}

.pop-up-success{
    position: fixed;
    /*display: flex;*/
    justify-content: flex-end;
    width: 100%;
    margin-top: 5px;
    padding-right: 5px;
    display: none;
}

.menu-style{
    display: flex;
    height: 52px;
    justify-content: space-between;
}


.vector-container{
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    text-decoration: none;
}

.logo-txt-container{
    height: 34px;
    width: 69px;
    left: 32px;
    margin-left: 15px;
    cursor: pointer;
}

.logo-txt{
    font-family: 'Montserrat Alternates', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 17px;
    text-align: left;
    color: white;
}

.search-container{
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.search-txt{
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 19px;
    text-align: center;
    color: white;
}

.img-search-container{
    margin-left: 10px;
}

.how-to-use-container{
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    text-decoration: none;
}

.how-to-use-txt{
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 19px;
    text-align: center;
    color: white;
    margin-right: 10px;
}

.how-to-use-img-container{
    margin-top: 4px;
}
