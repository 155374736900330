.txt-header-container {
    width: 50%;
}


.body-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;
    background: #F6FBFF;
    box-sizing: border-box;
}

.gif-image {
    width: 100%;
    height: 100%;
}

.inner-layout-parent {
    background: white;
    padding-top: 50px;
    padding-bottom: 20px;
    box-sizing: border-box;
}

.main-welcome-page-container{
    width: 100%;
    box-sizing: border-box;
}

.first-element-container{
    display: flex;
    width: 100%;
    justify-content: space-between;
    box-sizing: border-box;
}


.txt-header{
    font-family: 'Montserrat', sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 29px;
    text-align: left;
    color:  #01509D;
    display: flex;
    box-sizing: border-box;
}

.gif-container{
    height: 250px;
    width: 50%;
    max-width: 444px;
    left: 649px;
    top: 88px;
    border-radius: 10px;
    background: #000000B2;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    box-sizing: border-box;
}

.description-txt{
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 17px;
    text-align: left;
    color: #161616;
    box-sizing: border-box;
}

.gif-txt{
    font-family: 'Montserrat', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    text-align: center;
    color: white;
    box-sizing: border-box;
}

.card-view-content{
    width: 100%;
    background: #F6FBFF;
    margin-top: 20px;
    box-sizing: border-box;
}

.second-row-container{
    display: flex;
    width: 100%;
    justify-content: space-between;
    box-sizing: border-box;
}

.header-txt-second-row{
    font-family: 'Inter', sans-serif;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 36px;
    text-align: left;
    color: black;
    box-sizing: border-box;
}

.txt-container-second-row{
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    box-sizing: border-box;
}

.btn-container{
    margin-top: 60px;
    box-sizing: border-box;
}

.card-container-second{
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit , minmax(200px, 1fr));
    grid-gap: 28px;
    justify-content: space-between;
    margin-top: 20px;
    box-sizing: border-box;
}

.uninstall-welcome {
    width: 100%;
    height: 100%;
    position: fixed;
    background: rgba(0, 0, 0, 0.6);
    justify-content: center;
    align-items: center;
    display: none;
}

.install-now-welcome {

}

@media screen and (max-width: 1177px) {
   .card-container-second {
       grid-gap: 72px;
   }
}

@media screen and (max-width: 1081px) {
    .card-container-second {
        grid-gap: 181px;
    }
}

@media screen and (max-width: 1010px) {
    .card-container-second {
        grid-gap: 11px;
    }
}

.more-cursors-btn-container{
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 18px;
    box-sizing: border-box;
}

.footer-container-welcome-page{
    margin-top: 50px;
    width: 100%;
    box-sizing: border-box;
}


.txt-description-second-row{
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 19px;
    text-align: left;
    color: #303030;
    box-sizing: border-box;
    margin-top: 10px;
}
