.body-container-last-used {
    min-height: 100vh;
    background: #F6FBFF;
    justify-content: space-between;
    display: flex;
    flex-direction: column;
}

.view-container{
    width: 100%;
    background: #F6FBFF;
}

.header-txt-last-used {
    font-family: 'Montserrat', sans-serif;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 44px;
    text-align: center;
    background-image: linear-gradient(269.42deg, #006EDD 0%, #004585 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-top: 40px;
}

.txt-container-last{
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: default;
}

.card-container-last-used{
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit , minmax(260px, 1fr));
    grid-gap: 8px;
    justify-content: space-between;
    margin-top: 20px;
}

@media screen and (max-width: 1177px) {
    .card-container-last-used {
        grid-gap: 101px;
    }
}

@media screen and (max-width: 1081px) {
    .card-container-last-used {
        grid-gap: 71px;
    }
}

@media screen and (max-width: 1010px) {
    .card-container-last-used {
        grid-gap: 13px;
    }
}

.btn-container-last-used{
    display: flex;
    justify-content: center;
    margin-top: 25px;
}

.footer-last-used{
    margin-top: 50px;
}

.collection-card-last-used{
    margin-top: 20px;
}
