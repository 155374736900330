* {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font-family: Inter, sans-serif;
    vertical-align: baseline;
    box-sizing: border-box;
}

[hidden] {
    display: none !important;
}

body{
    width: 100vw;
    height: 100vh;
    padding: 0;
    margin: 0;
    font-family: Inter, sans-serif;
    background: linear-gradient(161.15deg, #6714CC 12.73%, #2E68F5 72.95%);
}

.wrapper{
    max-width: 1024px;
    margin: 0 auto;
    height: 100vh;
}
header{
    display: flex;
    align-items: center;
    width: 100%;
    height: 66px;
    color: #FFFFFF;
}
header img{
    max-width: 26px;
    margin-left: 12px;
}
header .header_text{
    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
}
.preview{
    width: 440px;
    color: #FFFFFF;
}

.preview_title{
    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    margin-bottom: 20px;
}

.preview_subtitle{
    font-weight: 400;
    font-size: 23px;
    line-height: 28px;
}
.rating_value_selected .preview_subtitle{
    display: none;
}
.preview_rating{
    width: 100%;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 8px;
}
.preview_rating_star{
    width:66px;
    height: 100%;
    margin-right: 10px;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url("../../assets/star_default.png");
}
.preview_rating:hover .preview_rating_star {
    background-image: url("../../assets/star_hover.png");
}
.preview_rating_star:hover ~ .preview_rating_star{
    background-image: url("../../assets/star_default.png");
}
.feedback_form{
    margin-top: 30px;
    font-weight: normal;
    font-size: 19px;
    line-height: 23px;
    display: none;
}

.rating_value_selected .feedback_form{
    display: block;
}

.feedback_form form{
    width: 100%;
}
.feedback_form form textarea{
    width: 100%;
    margin-top: 10px;
    background: #FFFFFF;
    border-radius: 10px;
    resize: none;
    height: 111px;
    border: none;
    outline: none;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #000000;
    padding: 15px;
    --placeholder-color: #6D6D6D;
}
.feedback_form form button{
    width: 100%;
    height: 40px;
    margin-top: 10px;
    border-radius: 8px;
    background: #00AB30;
    cursor: pointer;
    color: #FFFFFF;
}

.feedback_form form button[disabled]{
    background: #838383;
    cursor: default;
}

.congrats{
    width: 100%;
    max-width: 310px;
    margin-top: 36px;
    color: #FFFFFF;
}
.congrats img {
    width: 100px;
}
.congrats_text{
    margin-top: 22px;
    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
}



