.install-btn-container {
    height: 48px;
    width: 396px;
    border-radius: 10px;
    background: linear-gradient(269.42deg, #006EDD 0%, #004585 100%);
    cursor: pointer;
}

.install-btn-container:hover {
    background: linear-gradient(269.42deg, rgba(0, 110, 221, 0.7) 0%, rgba(0, 69, 133, 0.7) 100%);
}

.inner-txt-install-container {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    align-items: center;
}

.txt-container-install {
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    text-align: center;
    color: white;
    margin-left: 15px;
}
