.stop-trying-btn{
    height: 28px;
    width: 186px;
    border-radius: 6px;
    background-color:  #004D9B;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    cursor: pointer;
}


.stop-trying-txt{
    font-family: 'Inter', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 15px;
    text-align: center;
    color: white;
}
